import { useI18n } from 'vue-i18n'
import { MetaObject } from '@nuxt/schema'
import ConstructHeadInputParameters from '~/models/Head/ConstructHeadInputParameters'

export default defineNuxtPlugin(() => {
    const config = useRuntimeConfig()
    const route = useRoute()

    return {
        provide: {
            constructHead(inputParameters: ConstructHeadInputParameters): MetaObject {
                const head: MetaObject = {
                    title: inputParameters.customTitle || inputParameters.headInfo.title,
                    script: [],
                    link: [],
                }

                const titleAddress = `Pilulka.${config.public.locale}`

                if (typeof head.title === 'string') {
                    if (!head.title.includes(titleAddress)) {
                        head.title += ` | ${titleAddress}`
                    }
                } else {
                    head.title = titleAddress
                }

                const canonical = inputParameters.customCanonical || inputParameters.headInfo.canonical
                if (canonical) {
                    head.link = [
                        {
                            rel: 'canonical',
                            href: canonical,
                        },
                    ]
                }

                const preloadFiles = inputParameters.preloadFiles || []
                preloadFiles.forEach((filePath) => {
                    // @ts-ignore
                    head.link.push({
                        rel: 'preload',
                        href: filePath,
                        as: 'image',
                    })
                })

                head.meta = []
                head.meta.push({
                    name: 'description',
                    content:
                        inputParameters.headInfo.metaDescription || inputParameters.customMetaDescription || useI18n().t('metaDescription'),
                })

                head.meta.push({
                    name: 'apple-mobile-web-app-capable',
                    content: 'yes',
                })

                head.meta.push({
                    property: 'og:url',
                    name: 'og:url',
                    content: inputParameters.ogUrl || `${config.public.apiUrl}${route.fullPath}`,
                })

                head.meta.push({
                    property: 'og:type',
                    name: 'og:type',
                    content: inputParameters.ogType || 'website',
                })

                head.meta.push({
                    property: 'og:description',
                    name: 'og:description',
                    content:
                        inputParameters.headInfo.metaDescription || inputParameters.customMetaDescription || useI18n().t('metaDescription'),
                })

                head.meta.push({
                    property: 'og:title',
                    name: 'og:title',
                    content: inputParameters.customTitle || inputParameters.headInfo.title || useI18n().t('metaDescription'),
                })

                head.meta.push({
                    property: 'og:image',
                    name: 'og:image',
                    content:
                        inputParameters.image ||
                        `https://www.pilulka.${config.public.locale}/images/png/banner/sharing_banner_fb_${config.public.locale}.png`,
                })

                head.meta.push({
                    name: 'twitter:card',
                    content:
                        inputParameters.image ||
                        `https://www.pilulka.${config.public.locale}/images/png/banner/sharing_banner_fb_${config.public.locale}.png`,
                })

                head.meta.push({
                    name: 'msapplication-navbutton-color',
                    content: '#ec008c',
                })

                if (inputParameters.headInfo.metaIndex) {
                    head.meta.push({
                        name: 'robots',
                        content: inputParameters.headInfo.metaIndex,
                    })
                }

                return head
            },
        },
    }
})
