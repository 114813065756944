import GtmProduct from '~/models/Product/GtmProduct'
import { useBasketStore } from '~/stores/basket'
import BasketProduct from '~/models/Basket/BasketProduct'

export const transformBasketProductToGtmProduct = (product: BasketProduct): GtmProduct => {
    const basketStore = useBasketStore()

    let price = product.price.priceNoVat
    const rrp = product.price.originalPriceNoVat
    let discount: number | null = null
    if (rrp && rrp > price) {
        discount = +(rrp - price).toFixed(2)
        price = rrp
    }

    return {
        isAvailable: product.status === 1,
        id: product.id,
        name: product.name,
        brand: product.brandName,
        producer: product.manufacturerName,
        rating: product.rate,
        availability: product.amountInStock,
        availabilityPeriod: product.availability.availabilityPeriod,
        price,
        discount,
        categories: product.categoryNames,
        quantity: basketStore.productAmounts[product.id] || 0,
        vat: product.vat,
    }
}
