export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"property":"theme-color","content":"#ffffff"},{"property":"start_url","content":"/?utm_source=pwa"}],"link":[{"rel":"preload","href":"https://use.typekit.net/af/64385c/000000000000000077542086/30/d?primer=7792b85dc8a4b5bd789aaeb0d3fa71588b6b54bf10e40bc1f973e60ac783a7b9&fvd=n4&v=3","as":"font","type":"font/woff2","crossorigin":""},{"rel":"icon","type":"image/x-icon","href":"/nuxt-assets/favicon.ico"},{"rel":"preconnect","href":"https://pilulkacz.vshcdn.net","crossorigin":"crossorigin"},{"rel":"dns-prefetch","href":"https://pilulkacz.vshcdn.net"},{"rel":"preload","href":"/nuxt-assets/images/lazy-load-error.svg","as":"image","type":"image/svg+xml"}],"style":[],"script":[],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false