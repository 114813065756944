import { useNuxtApp } from '#app'
import { useBasketStore } from '~/stores/basket'

export default defineNuxtPlugin((nuxtApp): void => {
    const registrationModalCookie = useRegistrationModalCookie()

    const config = useRuntimeConfig()
    const route = useRoute()
    const basketStore = useBasketStore()

    nuxtApp.hook('page:finish', () => {
        registrationModalCookie.value.pageLoadedCounter++

        if (basketStore.authUser) {
            return
        }

        const shouldShowModalImmediately =
            config.public.popUpRegistration &&
            Object.keys(route.query).includes('pop-up-registration') &&
            route.query['pop-up-registration'] === 'open'
        if (
            (registrationModalCookie.value.pageLoadedCounter !== 5 || registrationModalCookie.value.modalShown) &&
            !shouldShowModalImmediately
        ) {
            return
        }

        if (route.path.startsWith('/kosik')) {
            registrationModalCookie.value = {
                pageLoadedCounter: 0,
                modalShown: false,
            }

            return
        }

        registrationModalCookie.value.modalShown = true
        setTimeout(() => {
            useNuxtApp().$bus.$emit('showRegistrationModal', {})
        }, 1000)
    })
})
