import { MetaObject } from '@nuxt/schema'
import SearchQueriesFormater from '~/mixins/SearchQueriesFormater'
import Repository from '~/api/repository'
import ConstructHeadInputParameters from '~/models/Head/ConstructHeadInputParameters'
// import {Device} from "@nuxtjs/device/runtime/types";

export interface Bus {
    $on: Function
    $off: Function
    $emit: Function
}

interface PluginsInjections {
    $t: Function
    $tm: Function
    $numberFormat: Function
    $formatCurrency: Function
    $distance: Function
    $repository: Repository
    $bus: Bus
    $constructHead: (inputParameters: ConstructHeadInputParameters) => MetaObject
    $device: any
    $highlightSearchedWord: Function
    showComparablePrices: boolean
}

declare module '#app' {
    interface NuxtApp extends PluginsInjections {}
}

// declare module 'nuxt/dist/app/nuxt' {
//     interface NuxtApp extends PluginsInjections {}
// }

declare module '@vue/runtime-core' {
    // @ts-ignore
    interface ComponentCustomProperties extends PluginsInjections {}
}

declare module '@nuxt/types' {
    interface Context extends PluginsInjections {}
}

let numberFormatter: any

const useNumberFormat = (locale: string, localeIso: string) => {
    if (!numberFormatter || numberFormatter.resolvedOptions().locale !== locale) {
        numberFormatter = Intl.NumberFormat(localeIso)
    }
}

export default defineNuxtPlugin(() => {
    const config = useRuntimeConfig()
    const numberFormat = (number: number) => {
        useNumberFormat(config.public.locale, config.public.localeIso)
        return numberFormatter.format(number, config.public.localeIso)
    }

    return {
        provide: {
            formatCurrency(number: number) {
                const price = +number
                if (!isFinite(price)) {
                    return null
                }
                // if (!process.client) {
                //     return price + " " + config.public.currencySymbol
                // }

                if (config.public.locale === 'ro' || config.public.locale === 'ro-RO') {
                    if (number === 1) {
                        return '1\xA0Leu'
                    }

                    if (number % 1 === 0) {
                        return `${number}\xA0Lei`
                    }

                    const [mainDigits, decimals] = number.toFixed(2).split('.')

                    return `${mainDigits}<sup>${decimals}</sup>\xA0Lei`
                }
                if (config.public.locale === 'sk' || config.public.locale === 'at' || config.public.locale === 'cz') {
                    const splitPrice = price.toFixed(2).toString().split('.')

                    const numberPart = splitPrice[0]
                    let decimalPart = splitPrice[1]

                    const thousands = /\B(?=(\d{3})+(?!\d))/g
                    if (decimalPart === '00') {
                        decimalPart = ''
                    }

                    return `${numberPart.replace(thousands, ' ')}${decimalPart ? ',' + decimalPart : ''} ${config.public.currencySymbol}`
                } else {
                    if (!config.public.currency) {
                        return price
                    }

                    return `${numberFormat(number)} ${config.public.currencySymbol}`
                }
            },
            cdnImage: (input: string) => {
                return input
            }, // CdnImage(config.public.imageUrl, process.env.NODE_ENV === 'development').filters.cdnImage,
            numberFormat,

            distance: (distanceInMeters: number) => {
                useNumberFormat(config.public.locale, config.public.localeIso)
                if (distanceInMeters < 1000) {
                    distanceInMeters = Math.round(distanceInMeters)

                    return `${numberFormatter.format(distanceInMeters)} m`
                }

                return `${numberFormatter.format(+(distanceInMeters / 1000).toFixed(1))} km`
            },

            highlightSearchedWord(text: string, query: string[]) {
                return SearchQueriesFormater.search.getWord(text, query)
            },
        },
    }
    //
    // Vue.mixin({ filters: {  } })
    // Vue.mixin(Truncate)
    // Vue.mixin(DateFormat)
    // Vue.mixin(SearchQueriesFormater)
    // Vue.mixin({ filters: { ...FormatCurrency(localeOptions).filters } })
    // Vue.mixin({ filters: { ...NumberFormat(localeOptions).filters } })
})
