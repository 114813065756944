// @ts-ignore
import md5 from 'md5'
import { defineNuxtPlugin, useRouter, useRuntimeConfig } from '#imports'
import { LayoutKey } from '#build/types/layouts'
import RouteCookie from '~/models/Cookie/RouteCookie'
import { usePerformanceStore } from '~/stores/performace'

declare global {
    interface Window {
        referrer: string
    }
}

function interopDefault(promise: Promise<any>) {
    return promise.then((m) => m.default || m)
}

const index = () => interopDefault(import('./../pages/index.vue'))
const category = () => interopDefault(import('./../pages/category/_.vue'))
const productDetail = () => interopDefault(import('./../pages/detail.vue'))
const orders = () => interopDefault(import('../pages/user/orders.vue'))
const favorite = () => interopDefault(import('./../pages/user/favorite.vue'))
const feedback = () => interopDefault(import('./../pages/user/feedback.vue'))
const profile = () => interopDefault(import('./../pages/user/profile.vue'))
const editProfile = () => interopDefault(import('./../pages/user/editProfile.vue'))
const order = () => interopDefault(import('../pages/user/order.vue'))
const staticSample = () => interopDefault(import('./../pages/staticSample.vue'))
const formRecommend = () => interopDefault(import('./../pages/formRecommend.vue'))
const empty = () => interopDefault(import('./../pages/empty.vue'))
const emptyNoLayout = () => interopDefault(import('./../pages/emptyNoLayout.vue'))
const staticPage = () => interopDefault(import('./../pages/static/_.vue'))
const redirectToLegacy = () => interopDefault(import('./../pages/redirectToLegacy.vue'))
const redirectPermanent = () => interopDefault(import('./../pages/redirectPermanent.vue'))
const termination = () => interopDefault(import('./../pages/termination.vue'))
const articleList = () => interopDefault(import('./../pages/articleList.vue'))
const paperAuthor = () => interopDefault(import('./../pages/paperAuthor.vue'))
const counsellingDetail = () => interopDefault(import('./../pages/counselling/detail.vue'))
const counsellingHomepage = () => interopDefault(import('./../pages/counselling/homepage.vue'))
const counsellingList = () => interopDefault(import('./../pages/counselling/list.vue'))
const cartStep1 = () => interopDefault(import('./../pages/cart/cartStep1.vue'))
const cartStep2 = () => interopDefault(import('./../pages/cart/cartStep2.vue'))
const cartStep3 = () => interopDefault(import('./../pages/cart/cartStep3.vue'))
const createComplaint = () => interopDefault(import('./../pages/complaint/createComplaint.vue'))
const complaintHomepage = () => interopDefault(import('./../pages/complaint/homepage.vue'))
const pharmacyList = () => interopDefault(import('./../pages/pharmacy/list.vue'))
const pharmacyDetail = () => interopDefault(import('./../pages/pharmacy/detail.vue'))
const cartStep4 = () => interopDefault(import('./../pages/cart/cartStep4.vue'))
const zasilkovnaDetail = () => interopDefault(import('./../pages/pickupPlace/zasilkovnaDetail.vue'))
const postOfficeDetail = () => interopDefault(import('./../pages/pickupPlace/postOfficeDetail.vue'))
const pplDetail = () => interopDefault(import('./../pages/pickupPlace/pplDetail.vue'))
const glsDetail = () => interopDefault(import('./../pages/pickupPlace/glsDetail.vue'))
const boxDetail = () => interopDefault(import('./../pages/pickupPlace/boxDetail.vue'))
const pickupPlaceList = () => interopDefault(import('./../pages/pickupPlace/list.vue'))
const bothersMeList = () => interopDefault(import('./../pages/bothersMe/list.vue'))
const bothersMeDetail = () => interopDefault(import('./../pages/category/_.vue'))
const registration = () => interopDefault(import('./../pages/user/registration.vue'))
const login = () => interopDefault(import('./../pages/user/login.vue'))
const forgottenPassword = () => interopDefault(import('./../pages/user/forgottenPassword.vue'))
const brandList = () => interopDefault(import('./../pages/brand/brandList.vue'))
const migraineQuestionnaire = () => interopDefault(import('./../pages/migraineQuestionnaire.vue'))
const migraineQuestionnaireResult = () => interopDefault(import('./../pages/migraineQuestionnaireResult.vue'))

let pages404: { [name: string]: string } = {}

// @ts-ignore
export default defineNuxtPlugin(async () => {
    const performanceStore = usePerformanceStore()
    const start = performance.now()
    const components: { [name: string]: any } = {
        homepage: index,
        // 'catalog.product': serviceDetail,
        'catalog.product': productDetail,
        'catalog.brand': category,
        'catalog.list': category, // TODO: We need to figure out solution when to show either category template or service list template.
        'catalog.search': category,
        // 'catalog.manufacturer': serviceManufacturer,
        'catalog.manufacturer': category,
        'catalog.product_line': category,
        'user.orders': orders,
        'user.favorite.my': favorite,
        'user.feedback': feedback,
        'user.profile': profile,
        'user.user_info': editProfile,
        'catalog.order': order,
        'static.page': staticPage,
        'static.sample': staticSample,
        'static.formRecommend': formRecommend,
        'static.empty': empty,
        'static.empty_no_layout': emptyNoLayout,
        'article.list': articleList,
        'catalog.paper_author': paperAuthor,
        redirectToLegacy,
        redirectPermanent,
        termination,
        'discussion.post': counsellingDetail,
        'counselling.homepage': counsellingHomepage,
        'counselling.list': counsellingList,
        'cart.cart': cartStep1,
        'cart.step_2': cartStep2,
        'cart.step_3': cartStep3,
        'pharmacy.list': pharmacyList,
        'pharmacy.detail': pharmacyDetail,
        'user.complaints.create': createComplaint,
        'user.complaints': complaintHomepage,
        'cart.order_sent': cartStep4,
        'zasilkovna.detail': zasilkovnaDetail,
        'post_office.detail': postOfficeDetail,
        'ppl_parcelshop.detail': pplDetail,
        'gls_parcelshop.detail': glsDetail,
        'pilulka_box_storage.detail': boxDetail,
        map: pickupPlaceList,
        'catalog.bothers_me_list': bothersMeList,
        'catalog.bothers_me': bothersMeDetail,
        'user.registration': registration,
        'user.login': login,
        'user.forgotten_password': forgottenPassword,
        'brands.list': brandList,
        'migraine.questionnaire': migraineQuestionnaire,
        'migraine.questionnaire.result': migraineQuestionnaireResult,
    }

    const responsesBySlug: { [name: string]: any } = {
        '/': {
            name: 'homepage',
            tag: 'homepage',
            params: {},
            queryParams: [],
            slug: '/',
            headInfo: {},
        },
        '/product-page': {
            name: 'catalog.product',
            tag: 'catalog.product',
            params: {},
            queryParams: [],
            slug: '/',
            headInfo: {},
        },
        '/category-page': {
            name: 'catalog.list',
            tag: 'catalog.list',
            params: {},
            queryParams: [],
            slug: '/',
            headInfo: {},
        },
        '/?pwa=1': {
            name: 'homepage',
            tag: 'homepage',
            params: {},
            queryParams: [],
            slug: '/?pwa=1',
            headInfo: {},
        },
        '/office-detail': {
            name: 'catalog.manufacturer_office',
            tag: 'catalog.manufacturer_office',
            params: {},
            queryParams: [],
            slug: '/office-detail',
            headInfo: {},
        },
        '/office-detail?pwa=1': {
            name: 'catalog.manufacturer_office',
            tag: 'catalog.manufacturer_office',
            params: {},
            queryParams: [],
            slug: '/office-detail?pwa=1',
            headInfo: {},
        },
        '/orders': {
            name: 'user.orders',
            tag: 'user.orders',
            params: {},
            queryParams: [],
            slug: '/orders',
            headInfo: {},
        },
        '/favorite': {
            name: 'user.favorite.my',
            tag: 'user.favorite.my',
            params: {},
            queryParams: [],
            slug: '/favorite',
            headInfo: {},
        },
        '/feedback': {
            name: 'user.feedback',
            tag: 'user.feedback',
            params: {},
            queryParams: [],
            slug: '/feedback',
            headInfo: {},
        },
        '/profile': {
            name: 'user.profile',
            tag: 'user.profile',
            params: {},
            queryParams: [],
            slug: '/profile',
            headInfo: {},
        },
        '/edit-profile': {
            name: 'user.user_info',
            tag: 'user.user_info',
            params: {},
            queryParams: [],
            slug: '/edit-profile',
            headInfo: {},
        },
        '/order': {
            name: 'catalog.order',
            tag: 'catalog.order',
            params: {},
            queryParams: [],
            slug: '/order',
            headInfo: {},
        },
        '/user?pwa=1': {
            name: 'catalog.user',
            tag: 'catalog.user',
            params: {},
            queryParams: [],
            slug: '/user?pwa=1',
            headInfo: {},
        },
        '/static-sample?pwa=1': {
            name: 'static.sample',
            tag: 'static.sample',
            params: {},
            queryParams: [],
            slug: '/static-sample?pwa=1',
            headInfo: {},
        },
        '/form-recommend?pwa=1': {
            name: 'static.formRecommend',
            tag: 'static.formRecommend',
            params: {},
            queryParams: [],
            slug: '/form-recommend?pwa=1',
            headInfo: {},
        },
        '/form-recommend': {
            name: 'static.formRecommend',
            tag: 'static.formRecommend',
            params: {},
            queryParams: [],
            slug: '/form-recommend',
            headInfo: {},
        },
        '/article-list': {
            name: 'article.list',
            tag: 'article.list',
            params: {},
            queryParams: [],
            slug: '/article-list',
            headInfo: {},
        },
        '/paper-author': {
            name: 'catalog.paper_author',
            tag: 'catalog.paper_author',
            params: {},
            queryParams: [],
            slug: '/',
            headInfo: {},
        },
        '/empty-page-2022': {
            name: 'static.empty',
            tag: 'static.empty',
            params: {},
            queryParams: [],
            slug: '/empty-page-2022',
            headInfo: {},
        },
        '/empty-page-2022-no-layout': {
            name: 'static.empty_no_layout',
            tag: 'static.empty_no_layout',
            params: {},
            queryParams: [],
            slug: '/empty-page-2022-no-layout',
            headInfo: {},
        },
        '/counselling-detail': {
            name: 'discussion.post',
            tag: 'discussion.post',
            params: {},
            queryParams: [],
            slug: '/counselling-detail',
            headInfo: {},
        },
        '/counselling-homepage': {
            name: 'counselling.homepage',
            tag: 'counselling.homepage',
            params: {},
            queryParams: [],
            slug: '/counselling-homepage',
            headInfo: {},
        },
        '/counselling-list': {
            name: 'counselling.list',
            tag: 'counselling.list',
            params: {},
            queryParams: [],
            slug: '/counselling-list',
            headInfo: {},
        },
        '/kosik': {
            name: 'cart.cart',
            tag: 'cart.cart',
            params: {},
            queryParams: [],
            slug: '/kosik',
            headInfo: {},
        },
        '/kosik-2': {
            name: 'cart.step_2',
            tag: 'cart.step_2',
            params: {},
            queryParams: [],
            slug: '/kosik-2',
            headInfo: {},
        },
        '/kosik-3': {
            name: 'cart.step_3',
            tag: 'cart.step_3',
            params: {},
            queryParams: [],
            slug: '/kosik-3',
            headInfo: {},
        },
        '/pilulka-lekarny': {
            name: 'pharmacy.list',
            tag: 'pharmacy.list',
            params: {},
            queryParams: [],
            slug: '/pilulka-lekarny',
            headInfo: {},
        },
        '/pharmacy-detail': {
            name: 'pharmacy.detail',
            tag: 'pharmacy.detail',
            params: {},
            queryParams: [],
            slug: '/pharmacy-detail',
            headInfo: {},
        },
        '/new-complaint': {
            name: 'user.complaints.create',
            tag: 'user.complaints.create',
            params: {},
            queryParams: [],
            slug: '/new-complaint',
            headInfo: {},
        },
        '/complaint-homepage': {
            name: 'user.complaints',
            tag: 'user.complaints',
            params: {},
            queryParams: [],
            slug: '/complaint-homepage',
            headInfo: {},
        },
        '/order-sent': {
            name: 'cart.order_sent',
            tag: 'cart.order_sent',
            params: {},
            queryParams: [],
            slug: '/objednavka-odeslana',
            headInfo: {},
        },
        '/zasilkovna-detail': {
            name: 'zasilkovna.detail',
            tag: 'zasilkovna.detail',
            params: {},
            queryParams: [],
            slug: '/zasilkovna-detail',
            headInfo: {},
        },
        '/post-office-detail': {
            name: 'post_office.detail',
            tag: 'post_office.detail',
            params: {},
            queryParams: [],
            slug: '/post-office-detail',
            headInfo: {},
        },
        '/ppl-parcelshop-detail': {
            name: 'ppl_parcelshop.detail',
            tag: 'ppl_parcelshop.detail',
            params: {},
            queryParams: [],
            slug: '/ppl-parcelshop-detail',
            headInfo: {},
        },
        '/gls-parcelshop-detail': {
            name: 'gls_parcelshop.detail',
            tag: 'gls_parcelshop.detail',
            params: {},
            queryParams: [],
            slug: '/gls-parcelshop-detail',
            headInfo: {},
        },
        '/pilulka-box-storage-detail': {
            name: 'pilulka_box_storage.detail',
            tag: 'pilulka_box_storage.detail',
            params: {},
            queryParams: [],
            slug: '/pilulka-box-storage-detail',
            headInfo: {},
        },
        '/bothers-me': {
            name: 'catalog.bothers_me_list',
            tag: 'catalog.bothers_me_list',
            params: {},
            queryParams: [],
            slug: '/bothers-me',
            headInfo: {},
        },

        '/bothers-me-detail': {
            name: 'catalog.bothers_me',
            tag: 'catalog.bothers_me',
            params: {},
            queryParams: [],
            slug: '/bothers-me-detail',
            headInfo: {},
        },

        '/registration': {
            name: 'user.registration',
            tag: 'user.registration',
            params: {},
            queryParams: [],
            slug: '/registration',
            headInfo: {},
        },

        '/login': {
            name: 'user.login',
            tag: 'user.login',
            params: {},
            queryParams: [],
            slug: '/login',
            headInfo: {},
        },

        '/forgotten_password': {
            name: 'user.forgotten_password',
            tag: 'user.forgotten_password',
            params: {},
            queryParams: [],
            slug: '/forgotten_password',
            headInfo: {},
        },

        '/dotaznik-migrena': {
            name: 'migraine.questionnaire',
            tag: 'migraine.questionnaire',
            params: {},
            queryParams: [],
            slug: '/dotaznik-migrena',
            headInfo: {},
        },

        '/dotaznik-migrena-vysledky': {
            name: 'migraine.questionnaire.result',
            tag: 'migraine.questionnaire.result',
            params: {},
            queryParams: [],
            slug: '/dotaznik-migrena-vysledky',
            headInfo: {},
        },
    }

    const router = useRouter()
    router.addRoute({
        path: '/product-page',
        component: components['catalog.product'],
        name: 'catalog.product',
        props: {},
        meta: {
            headInfo: {},
        },
    })
    router.addRoute({
        path: '/category-page',
        component: components['catalog.list'],
        name: 'catalog.list',
        props: {},
        meta: {
            headInfo: {},
        },
    })

    if (process.client) {
        try {
            const firstRoute = useCookie<RouteCookie>('route').value
            responsesBySlug[firstRoute.path] = {
                name: firstRoute.name,
                tag: firstRoute.tag,
                params: firstRoute.params,
                queryParams: firstRoute.query,
                slug: firstRoute.path,
                headInfo: firstRoute.headInfo,
            }
            const props = firstRoute.params

            props.componentName = firstRoute.name
            router.addRoute({
                path: firstRoute.path,
                component: components[firstRoute.name],
                name: firstRoute.tag,
                props,
                meta: {
                    layout: firstRoute.layout,
                    headInfo: firstRoute.headInfo,
                },
            })
        } catch (error) {}
    }

    const routeCookie = useCookie<RouteCookie>('route')

    const namesAdded: string[] = []
    const config = useRuntimeConfig()
    const redis = await useRedis()
    router.beforeEach(async (to, from): Promise<any> => {
        const startBeforeEach = performance.now()
        const pathFull = to.fullPath.replaceAll('%27', '').replaceAll("'", '')
        let pathNoHash = pathFull.split('#')[0]
        const path = pathFull.split('#')[0].split('?')[0]

        if (process.client) {
            window.referrer = from.fullPath
        }

        if (config.public.locale === 'ro' || config.public.locale === 'at' || config.public.locale === 'hu') {
            if (!namesAdded.includes(pathFull)) {
                namesAdded.push(pathFull)

                const terminationRoute = '/termination'
                router.addRoute({
                    path: terminationRoute,
                    component: components.termination,
                    name: terminationRoute,
                    props: {},
                    meta: {
                        layout: false,
                        headInfo: {},
                    },
                })

                if (path !== terminationRoute) {
                    router.getRoutes().forEach((route) => {
                        if (route.path === path && route.name !== undefined) {
                            router.removeRoute(route.name)
                        }
                    })

                    router.addRoute({
                        path,
                        component: components.redirectPermanent,
                        name: path,
                        meta: {
                            redirectTo: terminationRoute,
                        },
                    })
                }

                performanceStore.add('router', performance.now() - startBeforeEach)
                return pathFull
            } else {
                performanceStore.add('router', performance.now() - startBeforeEach)
                return
            }
        }

        const splitPath = path.split('/')
        const orderSentPath = /^\/objednavka-od[eo]slana/
        const pathStart = '/' + splitPath[1]
        if (orderSentPath.test(pathStart)) {
            const slugPath = '/order-sent'
            const orderNumberToken = splitPath.pop()
            if (responsesBySlug[slugPath]) {
                responsesBySlug[slugPath].slug = path
                responsesBySlug[slugPath].params = { orderNumberToken }

                pathNoHash = slugPath
            }
        }

        const suffixPattern = /\.(js|css|svg|jpg|png)$/i
        if (suffixPattern.test(path) || pages404[pathFull] === pathFull) {
            // throw createError({
            //     statusCode: 404,
            //     fatal: true,
            //     statusMessage: 'Url not found ' + pathNoHash,
            // })
        }

        let responseData
        if (responsesBySlug[pathNoHash]) {
            responseData = responsesBySlug[pathNoHash]
        } else if (responsesBySlug[path]) {
            responseData = responsesBySlug[path]
        } else {
            if (process.server && config.public.redisRoutingEnabled) {
                responseData = await preloadRouteFromRedis(path, to.query)
            }
            if (!responseData) {
                if (process.server) {
                    console.log('API router called from server', path)
                }
                try {
                    const baseUrl = process.client ? config.public.apiUrl : config.public.apiUrlInsideDocker
                    const body = { url: pathNoHash }
                    const response: any = await $fetch(`${baseUrl}/api/v2/pwa/router`, {
                        method: 'POST',
                        body,
                    })
                    responseData = response.response
                    if (response.result === false) {
                        throw new Error('Not found')
                    }
                } catch (error) {
                    pages404[pathFull] = pathFull
                    if (Object.keys(pages404).length > 500) {
                        pages404 = {}
                    }
                    throw createError({
                        statusCode: 404,
                        statusMessage: 'Url not found ' + pathNoHash,
                    })
                }
            }
        }
        responsesBySlug[pathNoHash] = responseData

        const redirectTo = responseData.redirectTo || null
        if (redirectTo) {
            if (!namesAdded.includes(pathFull)) {
                namesAdded.push(pathFull)
                // router.removeRoute(path)
                router.addRoute({
                    path,
                    component: components.redirectPermanent,
                    name: path,
                    meta: {
                        redirectTo,
                    },
                })

                return pathFull
            } else {
                return
            }
        }

        const name = responseData.tag
        if (to.name === name) {
            return
        }

        const componentName = responseData.name
        const params = responseData.params
        params.componentName = componentName
        // const queryParams = to.query // responseData.queryParams
        const slug = responseData.slug

        const component = components[componentName]

        if (!component) {
            routeCookie.value = {
                name: responseData.name,
                tag: name,
                path: slug,
                query: params,
                params,
                fullPath: pathFull,
                layout: false,
                headInfo: responseData.headInfo,
            }

            namesAdded.push(name)
            router.addRoute({
                path: slug,
                component: components.redirectToLegacy,
                name,
                props: params,
                meta: {
                    headInfo: responseData.headInfo,
                },
            })

            return pathFull
        }

        let layout: LayoutKey | false = 'default'
        if (responseData.name === 'static.empty_no_layout') {
            layout = false
        }

        routeCookie.value = {
            name: responseData.name,
            tag: name,
            path: slug,
            query: params,
            params,
            fullPath: pathFull,
            layout,
            headInfo: responseData.headInfo,
        }

        performanceStore.add('router', performance.now() - startBeforeEach)
        if (!namesAdded.includes(name)) {
            namesAdded.push(name)
            router.addRoute({
                path: slug,
                component,
                name,
                props: params,
                meta: {
                    layout,
                    headInfo: responseData.headInfo,
                    routeType: responseData.name,
                },
            })

            return pathFull
        }
    })

    const preloadRouteFromRedis = async (path: string, queryParams: { c?: string }) => {
        if ((queryParams.c || null) !== null) {
            return false
        }

        const record = await redis.hgetall('seorouting.alias.' + path)
        if (record.redirectTo) {
            return {
                redirectTo: record.redirectTo,
            }
        }

        const resolveCanonical = (route: any) => {
            if (route.metaIndex === 'noindex, nofollow' || route.metaIndex === 'noindex, follow') {
                return ''
            }

            if (route.canonical !== undefined && route.canonical !== '' && path.split('/').length < 2) {
                return route.canonical
            }

            return `${config.public.apiUrl}/${route.alias}`
        }

        if (record.id) {
            const params = JSON.parse(record.parameters)

            const paramsHash = md5(JSON.stringify(params))
            const queryParamsHash = md5(JSON.stringify(queryParams))
            const tag = `record.tag_${paramsHash}_${queryParamsHash}`

            return {
                name: record.type,
                tag,
                headInfo: {
                    title: record.title,
                    metaIndex: record.metaIndex,
                    metaDescription: record.metaDescription,
                    canonical: resolveCanonical(record),
                },
                queryParams,
                params: JSON.parse(record.parameters),
                slug: path,
            }
        } else {
            return false
        }
    }
    performanceStore.add('router', performance.now() - start)
})
