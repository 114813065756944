import { default as articleList0G8CZp7meCMeta } from "/usr/src/app/pages/articleList.vue?macro=true";
import { default as listm6t0KPaNqNMeta } from "/usr/src/app/pages/bothersMe/list.vue?macro=true";
import { default as brandListShNPiTLTy2Meta } from "/usr/src/app/pages/brand/brandList.vue?macro=true";
import { default as cartStep1x1SRwC2ZBnMeta } from "/usr/src/app/pages/cart/cartStep1.vue?macro=true";
import { default as cartStep2vgyPUj2MMgMeta } from "/usr/src/app/pages/cart/cartStep2.vue?macro=true";
import { default as cartStep3uKMDeVASKgMeta } from "/usr/src/app/pages/cart/cartStep3.vue?macro=true";
import { default as cartStep4mzD8jzC2sHMeta } from "/usr/src/app/pages/cart/cartStep4.vue?macro=true";
import { default as _6OYIWfxvddMeta } from "/usr/src/app/pages/category/_.vue?macro=true";
import { default as createComplaint0gOu6Qucp1Meta } from "/usr/src/app/pages/complaint/createComplaint.vue?macro=true";
import { default as homepagevIttuXxG96Meta } from "/usr/src/app/pages/complaint/homepage.vue?macro=true";
import { default as detail5cSb7PgsohMeta } from "/usr/src/app/pages/counselling/detail.vue?macro=true";
import { default as homepagezr6P8GHRmOMeta } from "/usr/src/app/pages/counselling/homepage.vue?macro=true";
import { default as list2KwbWktmZeMeta } from "/usr/src/app/pages/counselling/list.vue?macro=true";
import { default as detaildYd9xgitIDMeta } from "/usr/src/app/pages/detail.vue?macro=true";
import { default as emptyTwqVDykXHeMeta } from "/usr/src/app/pages/empty.vue?macro=true";
import { default as emptyNoLayoutPgD8EqihvtMeta } from "/usr/src/app/pages/emptyNoLayout.vue?macro=true";
import { default as emptyNoLayoutNoGTMzhJFXUIag6Meta } from "/usr/src/app/pages/emptyNoLayoutNoGTM.vue?macro=true";
import { default as formRecommendBdbRFu5N8gMeta } from "/usr/src/app/pages/formRecommend.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as migraineQuestionnairewGthxUEkmiMeta } from "/usr/src/app/pages/migraineQuestionnaire.vue?macro=true";
import { default as migraineQuestionnaireResultvPFn3swvoNMeta } from "/usr/src/app/pages/migraineQuestionnaireResult.vue?macro=true";
import { default as paperAuthordy12kge36WMeta } from "/usr/src/app/pages/paperAuthor.vue?macro=true";
import { default as detailzQOktCWToLMeta } from "/usr/src/app/pages/pharmacy/detail.vue?macro=true";
import { default as list2E14W0I0hUMeta } from "/usr/src/app/pages/pharmacy/list.vue?macro=true";
import { default as boxDetailxqLNv3MjqgMeta } from "/usr/src/app/pages/pickupPlace/boxDetail.vue?macro=true";
import { default as detailICYU515G58Meta } from "/usr/src/app/pages/pickupPlace/detail.vue?macro=true";
import { default as glsDetail979rrqhm4cMeta } from "/usr/src/app/pages/pickupPlace/glsDetail.vue?macro=true";
import { default as listMtdDwqY2xBMeta } from "/usr/src/app/pages/pickupPlace/list.vue?macro=true";
import { default as postOfficeDetailNzMw8zbPGlMeta } from "/usr/src/app/pages/pickupPlace/postOfficeDetail.vue?macro=true";
import { default as pplDetailcjiOAemjNvMeta } from "/usr/src/app/pages/pickupPlace/pplDetail.vue?macro=true";
import { default as zasilkovnaDetailTQPgRkZb4KMeta } from "/usr/src/app/pages/pickupPlace/zasilkovnaDetail.vue?macro=true";
import { default as redirectPermanentozF5VWMgBiMeta } from "/usr/src/app/pages/redirectPermanent.vue?macro=true";
import { default as redirectToLegacyA4nKKagwhaMeta } from "/usr/src/app/pages/redirectToLegacy.vue?macro=true";
import { default as _C9WK7nOgIUMeta } from "/usr/src/app/pages/static/_.vue?macro=true";
import { default as staticSamplexAEkyhJ5sXMeta } from "/usr/src/app/pages/staticSample.vue?macro=true";
import { default as terminationQmxmk1JKXSMeta } from "/usr/src/app/pages/termination.vue?macro=true";
import { default as editProfileq6aS4JogMCMeta } from "/usr/src/app/pages/user/editProfile.vue?macro=true";
import { default as favoriteKbM64gy00GMeta } from "/usr/src/app/pages/user/favorite.vue?macro=true";
import { default as feedbackLFoLnKIOrBMeta } from "/usr/src/app/pages/user/feedback.vue?macro=true";
import { default as forgottenPasswordn5kkqMgTGTMeta } from "/usr/src/app/pages/user/forgottenPassword.vue?macro=true";
import { default as loginOXuNuqxJEXMeta } from "/usr/src/app/pages/user/login.vue?macro=true";
import { default as orderm2x1qtCtMJMeta } from "/usr/src/app/pages/user/order.vue?macro=true";
import { default as ordersmghRD5id0LMeta } from "/usr/src/app/pages/user/orders.vue?macro=true";
import { default as profileckC0zuSrddMeta } from "/usr/src/app/pages/user/profile.vue?macro=true";
import { default as registrationGoe6img7KCMeta } from "/usr/src/app/pages/user/registration.vue?macro=true";
import { default as userVoucherTestPZAgXrAvddMeta } from "/usr/src/app/pages/userVoucherTest.vue?macro=true";
export default [
  {
    name: articleList0G8CZp7meCMeta?.name ?? "articleList",
    path: articleList0G8CZp7meCMeta?.path ?? "/articleList",
    meta: articleList0G8CZp7meCMeta || {},
    alias: articleList0G8CZp7meCMeta?.alias || [],
    redirect: articleList0G8CZp7meCMeta?.redirect,
    component: () => import("/usr/src/app/pages/articleList.vue").then(m => m.default || m)
  },
  {
    name: listm6t0KPaNqNMeta?.name ?? "bothersMe-list",
    path: listm6t0KPaNqNMeta?.path ?? "/bothersMe/list",
    meta: listm6t0KPaNqNMeta || {},
    alias: listm6t0KPaNqNMeta?.alias || [],
    redirect: listm6t0KPaNqNMeta?.redirect,
    component: () => import("/usr/src/app/pages/bothersMe/list.vue").then(m => m.default || m)
  },
  {
    name: brandListShNPiTLTy2Meta?.name ?? "brand-brandList",
    path: brandListShNPiTLTy2Meta?.path ?? "/brand/brandList",
    meta: brandListShNPiTLTy2Meta || {},
    alias: brandListShNPiTLTy2Meta?.alias || [],
    redirect: brandListShNPiTLTy2Meta?.redirect,
    component: () => import("/usr/src/app/pages/brand/brandList.vue").then(m => m.default || m)
  },
  {
    name: cartStep1x1SRwC2ZBnMeta?.name ?? "cart-cartStep1",
    path: cartStep1x1SRwC2ZBnMeta?.path ?? "/cart/cartStep1",
    meta: cartStep1x1SRwC2ZBnMeta || {},
    alias: cartStep1x1SRwC2ZBnMeta?.alias || [],
    redirect: cartStep1x1SRwC2ZBnMeta?.redirect,
    component: () => import("/usr/src/app/pages/cart/cartStep1.vue").then(m => m.default || m)
  },
  {
    name: cartStep2vgyPUj2MMgMeta?.name ?? "cart-cartStep2",
    path: cartStep2vgyPUj2MMgMeta?.path ?? "/cart/cartStep2",
    meta: cartStep2vgyPUj2MMgMeta || {},
    alias: cartStep2vgyPUj2MMgMeta?.alias || [],
    redirect: cartStep2vgyPUj2MMgMeta?.redirect,
    component: () => import("/usr/src/app/pages/cart/cartStep2.vue").then(m => m.default || m)
  },
  {
    name: cartStep3uKMDeVASKgMeta?.name ?? "cart-cartStep3",
    path: cartStep3uKMDeVASKgMeta?.path ?? "/cart/cartStep3",
    meta: cartStep3uKMDeVASKgMeta || {},
    alias: cartStep3uKMDeVASKgMeta?.alias || [],
    redirect: cartStep3uKMDeVASKgMeta?.redirect,
    component: () => import("/usr/src/app/pages/cart/cartStep3.vue").then(m => m.default || m)
  },
  {
    name: cartStep4mzD8jzC2sHMeta?.name ?? "cart-cartStep4",
    path: cartStep4mzD8jzC2sHMeta?.path ?? "/cart/cartStep4",
    meta: cartStep4mzD8jzC2sHMeta || {},
    alias: cartStep4mzD8jzC2sHMeta?.alias || [],
    redirect: cartStep4mzD8jzC2sHMeta?.redirect,
    component: () => import("/usr/src/app/pages/cart/cartStep4.vue").then(m => m.default || m)
  },
  {
    name: _6OYIWfxvddMeta?.name ?? "category-_",
    path: _6OYIWfxvddMeta?.path ?? "/category/_",
    meta: _6OYIWfxvddMeta || {},
    alias: _6OYIWfxvddMeta?.alias || [],
    redirect: _6OYIWfxvddMeta?.redirect,
    component: () => import("/usr/src/app/pages/category/_.vue").then(m => m.default || m)
  },
  {
    name: createComplaint0gOu6Qucp1Meta?.name ?? "complaint-createComplaint",
    path: createComplaint0gOu6Qucp1Meta?.path ?? "/complaint/createComplaint",
    meta: createComplaint0gOu6Qucp1Meta || {},
    alias: createComplaint0gOu6Qucp1Meta?.alias || [],
    redirect: createComplaint0gOu6Qucp1Meta?.redirect,
    component: () => import("/usr/src/app/pages/complaint/createComplaint.vue").then(m => m.default || m)
  },
  {
    name: homepagevIttuXxG96Meta?.name ?? "complaint-homepage",
    path: homepagevIttuXxG96Meta?.path ?? "/complaint/homepage",
    meta: homepagevIttuXxG96Meta || {},
    alias: homepagevIttuXxG96Meta?.alias || [],
    redirect: homepagevIttuXxG96Meta?.redirect,
    component: () => import("/usr/src/app/pages/complaint/homepage.vue").then(m => m.default || m)
  },
  {
    name: detail5cSb7PgsohMeta?.name ?? "counselling-detail",
    path: detail5cSb7PgsohMeta?.path ?? "/counselling/detail",
    meta: detail5cSb7PgsohMeta || {},
    alias: detail5cSb7PgsohMeta?.alias || [],
    redirect: detail5cSb7PgsohMeta?.redirect,
    component: () => import("/usr/src/app/pages/counselling/detail.vue").then(m => m.default || m)
  },
  {
    name: homepagezr6P8GHRmOMeta?.name ?? "counselling-homepage",
    path: homepagezr6P8GHRmOMeta?.path ?? "/counselling/homepage",
    meta: homepagezr6P8GHRmOMeta || {},
    alias: homepagezr6P8GHRmOMeta?.alias || [],
    redirect: homepagezr6P8GHRmOMeta?.redirect,
    component: () => import("/usr/src/app/pages/counselling/homepage.vue").then(m => m.default || m)
  },
  {
    name: list2KwbWktmZeMeta?.name ?? "counselling-list",
    path: list2KwbWktmZeMeta?.path ?? "/counselling/list",
    meta: list2KwbWktmZeMeta || {},
    alias: list2KwbWktmZeMeta?.alias || [],
    redirect: list2KwbWktmZeMeta?.redirect,
    component: () => import("/usr/src/app/pages/counselling/list.vue").then(m => m.default || m)
  },
  {
    name: detaildYd9xgitIDMeta?.name ?? "detail",
    path: detaildYd9xgitIDMeta?.path ?? "/detail",
    meta: detaildYd9xgitIDMeta || {},
    alias: detaildYd9xgitIDMeta?.alias || [],
    redirect: detaildYd9xgitIDMeta?.redirect,
    component: () => import("/usr/src/app/pages/detail.vue").then(m => m.default || m)
  },
  {
    name: emptyTwqVDykXHeMeta?.name ?? "empty",
    path: emptyTwqVDykXHeMeta?.path ?? "/empty",
    meta: emptyTwqVDykXHeMeta || {},
    alias: emptyTwqVDykXHeMeta?.alias || [],
    redirect: emptyTwqVDykXHeMeta?.redirect,
    component: () => import("/usr/src/app/pages/empty.vue").then(m => m.default || m)
  },
  {
    name: emptyNoLayoutPgD8EqihvtMeta?.name ?? "emptyNoLayout",
    path: emptyNoLayoutPgD8EqihvtMeta?.path ?? "/emptyNoLayout",
    meta: emptyNoLayoutPgD8EqihvtMeta || {},
    alias: emptyNoLayoutPgD8EqihvtMeta?.alias || [],
    redirect: emptyNoLayoutPgD8EqihvtMeta?.redirect,
    component: () => import("/usr/src/app/pages/emptyNoLayout.vue").then(m => m.default || m)
  },
  {
    name: emptyNoLayoutNoGTMzhJFXUIag6Meta?.name ?? "emptyNoLayoutNoGTM",
    path: emptyNoLayoutNoGTMzhJFXUIag6Meta?.path ?? "/emptyNoLayoutNoGTM",
    meta: emptyNoLayoutNoGTMzhJFXUIag6Meta || {},
    alias: emptyNoLayoutNoGTMzhJFXUIag6Meta?.alias || [],
    redirect: emptyNoLayoutNoGTMzhJFXUIag6Meta?.redirect,
    component: () => import("/usr/src/app/pages/emptyNoLayoutNoGTM.vue").then(m => m.default || m)
  },
  {
    name: formRecommendBdbRFu5N8gMeta?.name ?? "formRecommend",
    path: formRecommendBdbRFu5N8gMeta?.path ?? "/formRecommend",
    meta: formRecommendBdbRFu5N8gMeta || {},
    alias: formRecommendBdbRFu5N8gMeta?.alias || [],
    redirect: formRecommendBdbRFu5N8gMeta?.redirect,
    component: () => import("/usr/src/app/pages/formRecommend.vue").then(m => m.default || m)
  },
  {
    name: indexgw2ikJsyhcMeta?.name ?? "index",
    path: indexgw2ikJsyhcMeta?.path ?? "/",
    meta: indexgw2ikJsyhcMeta || {},
    alias: indexgw2ikJsyhcMeta?.alias || [],
    redirect: indexgw2ikJsyhcMeta?.redirect,
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: migraineQuestionnairewGthxUEkmiMeta?.name ?? "migraineQuestionnaire",
    path: migraineQuestionnairewGthxUEkmiMeta?.path ?? "/migraineQuestionnaire",
    meta: migraineQuestionnairewGthxUEkmiMeta || {},
    alias: migraineQuestionnairewGthxUEkmiMeta?.alias || [],
    redirect: migraineQuestionnairewGthxUEkmiMeta?.redirect,
    component: () => import("/usr/src/app/pages/migraineQuestionnaire.vue").then(m => m.default || m)
  },
  {
    name: migraineQuestionnaireResultvPFn3swvoNMeta?.name ?? "migraineQuestionnaireResult",
    path: migraineQuestionnaireResultvPFn3swvoNMeta?.path ?? "/migraineQuestionnaireResult",
    meta: migraineQuestionnaireResultvPFn3swvoNMeta || {},
    alias: migraineQuestionnaireResultvPFn3swvoNMeta?.alias || [],
    redirect: migraineQuestionnaireResultvPFn3swvoNMeta?.redirect,
    component: () => import("/usr/src/app/pages/migraineQuestionnaireResult.vue").then(m => m.default || m)
  },
  {
    name: paperAuthordy12kge36WMeta?.name ?? "paperAuthor",
    path: paperAuthordy12kge36WMeta?.path ?? "/paperAuthor",
    meta: paperAuthordy12kge36WMeta || {},
    alias: paperAuthordy12kge36WMeta?.alias || [],
    redirect: paperAuthordy12kge36WMeta?.redirect,
    component: () => import("/usr/src/app/pages/paperAuthor.vue").then(m => m.default || m)
  },
  {
    name: detailzQOktCWToLMeta?.name ?? "pharmacy-detail",
    path: detailzQOktCWToLMeta?.path ?? "/pharmacy/detail",
    meta: detailzQOktCWToLMeta || {},
    alias: detailzQOktCWToLMeta?.alias || [],
    redirect: detailzQOktCWToLMeta?.redirect,
    component: () => import("/usr/src/app/pages/pharmacy/detail.vue").then(m => m.default || m)
  },
  {
    name: list2E14W0I0hUMeta?.name ?? "pharmacy-list",
    path: list2E14W0I0hUMeta?.path ?? "/pharmacy/list",
    meta: list2E14W0I0hUMeta || {},
    alias: list2E14W0I0hUMeta?.alias || [],
    redirect: list2E14W0I0hUMeta?.redirect,
    component: () => import("/usr/src/app/pages/pharmacy/list.vue").then(m => m.default || m)
  },
  {
    name: boxDetailxqLNv3MjqgMeta?.name ?? "pickupPlace-boxDetail",
    path: boxDetailxqLNv3MjqgMeta?.path ?? "/pickupPlace/boxDetail",
    meta: boxDetailxqLNv3MjqgMeta || {},
    alias: boxDetailxqLNv3MjqgMeta?.alias || [],
    redirect: boxDetailxqLNv3MjqgMeta?.redirect,
    component: () => import("/usr/src/app/pages/pickupPlace/boxDetail.vue").then(m => m.default || m)
  },
  {
    name: detailICYU515G58Meta?.name ?? "pickupPlace-detail",
    path: detailICYU515G58Meta?.path ?? "/pickupPlace/detail",
    meta: detailICYU515G58Meta || {},
    alias: detailICYU515G58Meta?.alias || [],
    redirect: detailICYU515G58Meta?.redirect,
    component: () => import("/usr/src/app/pages/pickupPlace/detail.vue").then(m => m.default || m)
  },
  {
    name: glsDetail979rrqhm4cMeta?.name ?? "pickupPlace-glsDetail",
    path: glsDetail979rrqhm4cMeta?.path ?? "/pickupPlace/glsDetail",
    meta: glsDetail979rrqhm4cMeta || {},
    alias: glsDetail979rrqhm4cMeta?.alias || [],
    redirect: glsDetail979rrqhm4cMeta?.redirect,
    component: () => import("/usr/src/app/pages/pickupPlace/glsDetail.vue").then(m => m.default || m)
  },
  {
    name: listMtdDwqY2xBMeta?.name ?? "pickupPlace-list",
    path: listMtdDwqY2xBMeta?.path ?? "/pickupPlace/list",
    meta: listMtdDwqY2xBMeta || {},
    alias: listMtdDwqY2xBMeta?.alias || [],
    redirect: listMtdDwqY2xBMeta?.redirect,
    component: () => import("/usr/src/app/pages/pickupPlace/list.vue").then(m => m.default || m)
  },
  {
    name: postOfficeDetailNzMw8zbPGlMeta?.name ?? "pickupPlace-postOfficeDetail",
    path: postOfficeDetailNzMw8zbPGlMeta?.path ?? "/pickupPlace/postOfficeDetail",
    meta: postOfficeDetailNzMw8zbPGlMeta || {},
    alias: postOfficeDetailNzMw8zbPGlMeta?.alias || [],
    redirect: postOfficeDetailNzMw8zbPGlMeta?.redirect,
    component: () => import("/usr/src/app/pages/pickupPlace/postOfficeDetail.vue").then(m => m.default || m)
  },
  {
    name: pplDetailcjiOAemjNvMeta?.name ?? "pickupPlace-pplDetail",
    path: pplDetailcjiOAemjNvMeta?.path ?? "/pickupPlace/pplDetail",
    meta: pplDetailcjiOAemjNvMeta || {},
    alias: pplDetailcjiOAemjNvMeta?.alias || [],
    redirect: pplDetailcjiOAemjNvMeta?.redirect,
    component: () => import("/usr/src/app/pages/pickupPlace/pplDetail.vue").then(m => m.default || m)
  },
  {
    name: zasilkovnaDetailTQPgRkZb4KMeta?.name ?? "pickupPlace-zasilkovnaDetail",
    path: zasilkovnaDetailTQPgRkZb4KMeta?.path ?? "/pickupPlace/zasilkovnaDetail",
    meta: zasilkovnaDetailTQPgRkZb4KMeta || {},
    alias: zasilkovnaDetailTQPgRkZb4KMeta?.alias || [],
    redirect: zasilkovnaDetailTQPgRkZb4KMeta?.redirect,
    component: () => import("/usr/src/app/pages/pickupPlace/zasilkovnaDetail.vue").then(m => m.default || m)
  },
  {
    name: redirectPermanentozF5VWMgBiMeta?.name ?? "redirectPermanent",
    path: redirectPermanentozF5VWMgBiMeta?.path ?? "/redirectPermanent",
    meta: redirectPermanentozF5VWMgBiMeta || {},
    alias: redirectPermanentozF5VWMgBiMeta?.alias || [],
    redirect: redirectPermanentozF5VWMgBiMeta?.redirect,
    component: () => import("/usr/src/app/pages/redirectPermanent.vue").then(m => m.default || m)
  },
  {
    name: redirectToLegacyA4nKKagwhaMeta?.name ?? "redirectToLegacy",
    path: redirectToLegacyA4nKKagwhaMeta?.path ?? "/redirectToLegacy",
    meta: redirectToLegacyA4nKKagwhaMeta || {},
    alias: redirectToLegacyA4nKKagwhaMeta?.alias || [],
    redirect: redirectToLegacyA4nKKagwhaMeta?.redirect,
    component: () => import("/usr/src/app/pages/redirectToLegacy.vue").then(m => m.default || m)
  },
  {
    name: _C9WK7nOgIUMeta?.name ?? "static-_",
    path: _C9WK7nOgIUMeta?.path ?? "/static/_",
    meta: _C9WK7nOgIUMeta || {},
    alias: _C9WK7nOgIUMeta?.alias || [],
    redirect: _C9WK7nOgIUMeta?.redirect,
    component: () => import("/usr/src/app/pages/static/_.vue").then(m => m.default || m)
  },
  {
    name: staticSamplexAEkyhJ5sXMeta?.name ?? "staticSample",
    path: staticSamplexAEkyhJ5sXMeta?.path ?? "/staticSample",
    meta: staticSamplexAEkyhJ5sXMeta || {},
    alias: staticSamplexAEkyhJ5sXMeta?.alias || [],
    redirect: staticSamplexAEkyhJ5sXMeta?.redirect,
    component: () => import("/usr/src/app/pages/staticSample.vue").then(m => m.default || m)
  },
  {
    name: terminationQmxmk1JKXSMeta?.name ?? "termination",
    path: terminationQmxmk1JKXSMeta?.path ?? "/termination",
    meta: terminationQmxmk1JKXSMeta || {},
    alias: terminationQmxmk1JKXSMeta?.alias || [],
    redirect: terminationQmxmk1JKXSMeta?.redirect,
    component: () => import("/usr/src/app/pages/termination.vue").then(m => m.default || m)
  },
  {
    name: editProfileq6aS4JogMCMeta?.name ?? "user-editProfile",
    path: editProfileq6aS4JogMCMeta?.path ?? "/user/editProfile",
    meta: editProfileq6aS4JogMCMeta || {},
    alias: editProfileq6aS4JogMCMeta?.alias || [],
    redirect: editProfileq6aS4JogMCMeta?.redirect,
    component: () => import("/usr/src/app/pages/user/editProfile.vue").then(m => m.default || m)
  },
  {
    name: favoriteKbM64gy00GMeta?.name ?? "user-favorite",
    path: favoriteKbM64gy00GMeta?.path ?? "/user/favorite",
    meta: favoriteKbM64gy00GMeta || {},
    alias: favoriteKbM64gy00GMeta?.alias || [],
    redirect: favoriteKbM64gy00GMeta?.redirect,
    component: () => import("/usr/src/app/pages/user/favorite.vue").then(m => m.default || m)
  },
  {
    name: feedbackLFoLnKIOrBMeta?.name ?? "user-feedback",
    path: feedbackLFoLnKIOrBMeta?.path ?? "/user/feedback",
    meta: feedbackLFoLnKIOrBMeta || {},
    alias: feedbackLFoLnKIOrBMeta?.alias || [],
    redirect: feedbackLFoLnKIOrBMeta?.redirect,
    component: () => import("/usr/src/app/pages/user/feedback.vue").then(m => m.default || m)
  },
  {
    name: forgottenPasswordn5kkqMgTGTMeta?.name ?? "user-forgottenPassword",
    path: forgottenPasswordn5kkqMgTGTMeta?.path ?? "/user/forgottenPassword",
    meta: forgottenPasswordn5kkqMgTGTMeta || {},
    alias: forgottenPasswordn5kkqMgTGTMeta?.alias || [],
    redirect: forgottenPasswordn5kkqMgTGTMeta?.redirect,
    component: () => import("/usr/src/app/pages/user/forgottenPassword.vue").then(m => m.default || m)
  },
  {
    name: loginOXuNuqxJEXMeta?.name ?? "user-login",
    path: loginOXuNuqxJEXMeta?.path ?? "/user/login",
    meta: loginOXuNuqxJEXMeta || {},
    alias: loginOXuNuqxJEXMeta?.alias || [],
    redirect: loginOXuNuqxJEXMeta?.redirect,
    component: () => import("/usr/src/app/pages/user/login.vue").then(m => m.default || m)
  },
  {
    name: orderm2x1qtCtMJMeta?.name ?? "user-order",
    path: orderm2x1qtCtMJMeta?.path ?? "/user/order",
    meta: orderm2x1qtCtMJMeta || {},
    alias: orderm2x1qtCtMJMeta?.alias || [],
    redirect: orderm2x1qtCtMJMeta?.redirect,
    component: () => import("/usr/src/app/pages/user/order.vue").then(m => m.default || m)
  },
  {
    name: ordersmghRD5id0LMeta?.name ?? "user-orders",
    path: ordersmghRD5id0LMeta?.path ?? "/user/orders",
    meta: ordersmghRD5id0LMeta || {},
    alias: ordersmghRD5id0LMeta?.alias || [],
    redirect: ordersmghRD5id0LMeta?.redirect,
    component: () => import("/usr/src/app/pages/user/orders.vue").then(m => m.default || m)
  },
  {
    name: profileckC0zuSrddMeta?.name ?? "user-profile",
    path: profileckC0zuSrddMeta?.path ?? "/user/profile",
    meta: profileckC0zuSrddMeta || {},
    alias: profileckC0zuSrddMeta?.alias || [],
    redirect: profileckC0zuSrddMeta?.redirect,
    component: () => import("/usr/src/app/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: registrationGoe6img7KCMeta?.name ?? "user-registration",
    path: registrationGoe6img7KCMeta?.path ?? "/user/registration",
    meta: registrationGoe6img7KCMeta || {},
    alias: registrationGoe6img7KCMeta?.alias || [],
    redirect: registrationGoe6img7KCMeta?.redirect,
    component: () => import("/usr/src/app/pages/user/registration.vue").then(m => m.default || m)
  },
  {
    name: userVoucherTestPZAgXrAvddMeta?.name ?? "userVoucherTest",
    path: userVoucherTestPZAgXrAvddMeta?.path ?? "/userVoucherTest",
    meta: userVoucherTestPZAgXrAvddMeta || {},
    alias: userVoucherTestPZAgXrAvddMeta?.alias || [],
    redirect: userVoucherTestPZAgXrAvddMeta?.redirect,
    component: () => import("/usr/src/app/pages/userVoucherTest.vue").then(m => m.default || m)
  }
]