import { defineStore } from 'pinia'
import FlashMessage from '~/models/FlashMessage'

export const useFlashMessagesStore = defineStore('index', {
    state: () => ({
        messages: [] as FlashMessage[],
    }),
    actions: {
        clear() {
            this.messages = []
        },

        // add message for actual page
        addNowMessage(message: FlashMessage) {
            this.messages.push(message)
        },

        // add message before redirect
        addNextMessage(message: FlashMessage) {
            const messages = useNextFlashMessagesCookie().value || []
            messages.push(message)
            useNextFlashMessagesCookie().value = messages
        },

        reload() {
            this.messages = useNextFlashMessagesCookie().value || []
            useNextFlashMessagesCookie().value = []
        },
    },
})
